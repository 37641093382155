<div class="contextmenu"  [ngStyle]="{'left.px': contextPositionX, 'top.px': contextPositionY}">
  <ul class="m-0 p-1">
    @for (menuItem of contextMenuItems; track menuItem; let i = $index) {
      <li  (click)="onContextMenuClick($event, menuItem.menuEvent)">
        {{menuItem.menuText}}
        @if (i < contextMenuItems.length - 1) {
          <hr class="m-0" />
        }
      </li>
    }
  </ul>
</div>
