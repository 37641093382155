import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContextMenuItems } from 'src/app/core/models/context-menu-items';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'app-context-menu',
    templateUrl: './context-menu.component.html',
    styleUrls: ['./context-menu.component.scss'],
    standalone: true,
    imports: [NgStyle]
})
export class ContextMenuComponent implements OnInit {
  @Input() contextPositionX: number
  @Input() contextPositionY: number
  @Input() contextMenuItems: ContextMenuItems[]
  constructor() { }

  ngOnInit() {
  }
  @Output()
  onContextMenuItemClick: EventEmitter<any> = new EventEmitter<any>();

  onContextMenuClick(event, data): any {
    this.onContextMenuItemClick.emit({
      event,
      data,
    });
  }
}
